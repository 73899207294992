@media  (min-width: 0px) and (max-width: 768px){
    #Section4{
        position: relative;
        padding: 0 0%;
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .line{
        display: none;
    }

    .S4-content{
        height: 70%;
        width: 100%;
        padding: 0% 5%;
    }
    
    .S4-content h2{
        color: #054F57;
        margin: 0px 0px 25px 0px;
    }

    .S4-content .S4-Btn{
        margin-bottom: 10%;
        height: 13vw;
        min-height: 46px;
        max-height: 46px;
    }
    .S4-image-contact{
        width: 100%;
        height: 200px;
        margin-bottom: 6%;
    }
}

@media  (min-width: 426px) and (max-width: 768px){
    .S4-image-contact{
        height: 250px;
    }

}

@media  (min-width: 600px) and (max-width: 769px){
    .S4-image-contact{
        height: 270px;
    }
}