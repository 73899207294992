.o-Section-Cont{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 90%;
    margin: 2rem 0px;
}

.o-Section-Cont .o-img-photo{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    width: auto;
    zoom: 11%;
    box-shadow: 0px 0px 0px 0px #eee;
    border-radius: 0;
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.o-carousel-tab{
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0 20px 0 20px;
    padding: 0%;
    width: 14rem;
    height: 17rem;
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 0 20px 0 20px;
    -moz-border-radius: 0 20px 0 20px;
    -ms-border-radius: 0 20px 0 20px;
    -o-border-radius: 0 20px 0 20px;
}

.o-Section-Cont .o-carousel-tab .o-carousel-cont{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 90%;
    width: 100%;
}

.o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-lkin {
    background-color: #AEB530;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px !important;
    width: 35px !important;
    margin: 10px;
    border-radius: 50px;
    overflow: hidden;
    transform: perspective(1px) translateZ(0);
    z-index: 1;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

.o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-lkin .o-link-lkin{
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-lkin .o-link-lkin img{
    width: 20px;
    height: 20px;
    box-shadow: 0px 0px 0px 0px #eee;
    border-radius: 0;
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

.o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-name{
    height: 20%;
    width: 100%;
    align-self: flex-end;
    background-color: rgba(174,181,48,1);
    border-radius: 0 100px 0 0;
    z-index: 1;
    -webkit-border-radius: 0 100px 0 0;
    -moz-border-radius: 0 100px 0 0;
    -ms-border-radius: 0 100px 0 0;
    -o-border-radius: 0 100px 0 0;
}

.o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-name .o-name-decoration{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255,255,255);
    border-radius: 0 30px 0 100px;
    -webkit-border-radius: 0 30px 0 100px;
    -moz-border-radius: 0 30px 0 100px;
    -ms-border-radius: 0 30px 0 100px;
    -o-border-radius: 0 30px 0 100px;
} 

.o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-name .o-name-decoration .o-name{
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    color: #008392;
}

.o-Section-Cont .o-carousel-tab .o-carousel-post{
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #efefef;
    z-index: 1;
}

.o-Section-Cont .o-carousel-tab .o-carousel-post .o-post-tittle{
    font-family: 'Quicksand';
    font-size: 1rem;
    font-weight: 100;
    color: #008392;
    margin: 0;
}

@media (max-width: 768px) {
    .o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-lkin {
        height: 20px !important;
        width: 20px !important;
        margin: 10px;
    }
    
    .o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-lkin .o-link-lkin{
        width: 20px;
        height: 20px;
    }
    
    .o-Section-Cont .o-carousel-tab .o-carousel-cont .o-carousel-lkin .o-link-lkin img{
        width: 10px;
        height: 10px;
    }
}







