.slider-services{
    width: 50%;
    height: 100%;
    background: #044f56;
    position: relative;
}

.carousel-team{
    width: 50%;
    height: 100%;
    background: #0e7182;
    position: relative;
    font-family: 'Quicksand', sans-serif;
}

.title-s-s{
    color: #FFFFFF;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.category-teamMembers{    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.category-teamMembers-img{
    position: relative;
    display: flex;
    background-color: #FFF;
    border-radius: 50%;
    border: 0px;
    /* width: 100%;
    height: 50%; */
    height: 11rem;
    width: 11rem;
    margin-bottom: 20px;
    /* border: 4px solid #FFF; */
    /* box-shadow:  0px 0vw 6px 1px #eee; */
    overflow: hidden;
    /*min-width: 112px;
    min-height: 99px;
    */justify-content: center;
    align-items: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.member-ctmi{
    height: 9rem;
    width: 9rem;
}

/* .category-teamMembers-img:hover{
    cursor: pointer;
} */

.category-teamMembers-img .img-team{
    position: relative;
    padding: 22%;
    width: 60%;
    height: 60%;
    z-index: 6;
}

.list .content-members .member-ctmi .img-team-member{
    /* width: 14rem;
    height: 14rem; */
    width: auto;
    zoom: 90%;
}
    
/* .BI:hover::after{
    transition: 500ms;
    cursor: pointer;
    bottom: -58%;
    -webkit-transition: 500ms;
    -moz-transition: 500ms;
    -ms-transition: 500ms;
    -o-transition: 500ms;
}
.UX:hover::after{
    transition: 500ms;
    cursor: pointer;
    bottom: -58%;
    -webkit-transition: 500ms;
    -moz-transition: 500ms;
    -ms-transition: 500ms;
    -o-transition: 500ms;
}
.Predictiva:hover::after{
    transition: 500ms;
    cursor: pointer;
    bottom: -58%;
    -webkit-transition: 500ms;
    -moz-transition: 500ms;
    -ms-transition: 500ms;
    -o-transition: 500ms;
} */

/* @media (min-width:1008px){
    .category-teamMembers-img{
        width: 11vw;
        height: 11vw;      
    }
} */

/* .category-teamMembers-img::before{
    content: "";
    margin: 0px;
    display: block;
    position: absolute;
    width: 185%;
    height: 180%;
    bottom: -29%;
    right: 64%;
    border-radius: 50%;
    background-color: #128d98;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
} */

/* .Predictiva::after{
    content: "";
    margin: 0px;
    display: block;
    position: absolute;
    width: 206%;
    height: 180%;
    bottom: -120%;
    left: -13%;
    border-radius: 50%;
    background-color: #044f56;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    transition: 500ms;
}
.UX::after{
    content: "";
    margin: 0px;
    display: block;
    position: absolute;
    width: 206%;
    height: 180%;
    bottom: -120%;
    left: -13%;
    border-radius: 50%;
    background-color: #7C267D;
    transition: 500ms;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.BI::after{
    content: "";
    margin: 0px;
    display: block;
    position: absolute;
    width: 206%;
    height: 180%;
    bottom: -120%;
    left: -13%;
    border-radius: 50%;
    background-color: #47b8b0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    transition: 500ms;
}
.Consultoría::after{
    content: "";
    margin: 0px;
    display: block;
    position: absolute;
    width: 206%;
    height: 180%;
    bottom: -120%;
    left: -13%;
    border-radius: 50%;
    background-color: #aeb530;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    transition: 500ms;
} */

.category-teamMembers-title,
.title-team{
    font-family: 'Quicksand';
    color: #FFF;
    font-weight: 300;
    font-size: 2rem;
}

.disable{
    display: none;
}

.content-members{
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.career{
    color: white;
    margin-top: 10px;
}

.linkdnIcon{
    background: #AEB530;
    width: 2rem;
    height: 2rem;
    margin-top: 10px;
    border-radius: 50%;
    padding: 0.4rem;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

/* .carousel-close{
    position: absolute;
    top: 40px;
    left: 10%;
    border: 0px;
    background-color: transparent;
    cursor: pointer;
    width: 40px;
    height: 40px;
    z-index: 6;
} */

/* .carousel-close img{
    -webkit-filter: drop-shadow(0px 0px 7px #464646);
            filter: drop-shadow(0px 0px 7px #464646);
    rotate: 180;
    transform: rotate(180deg)
}
.carousel-close:hover img{
    -webkit-filter: brightness(0) saturate(100%) invert(23%) sepia(32%) saturate(7048%) hue-rotate(171deg) brightness(102%) contrast(103%) drop-shadow(0px 0px 0px #46464600);
            filter: brightness(0) saturate(100%) invert(23%) sepia(32%) saturate(7048%) hue-rotate(171deg) brightness(102%) contrast(103%) drop-shadow(0px 0px 0px #46464600);
} */

