#header{
    display: flex;
    /* padding: 0px 75px; */
    align-items: center;
    justify-content: center;
    height: 82px;
    background-color: #F4F4F4;
    z-index: 200;
    position: -webkit-sticky;
    position: sticky;
    box-shadow: 0px 3px 4px 0px rgba(54, 54, 54, 0.3);

    
}
.header{
    display: flex;
    align-items: center;
    height: 82px;
    background-color: #F4F4F4;
    /* z-index: 200; */
    position: relative;
    overflow: visible;
}
.header .homeLink{
    /* position: absolute; */
    cursor: pointer;
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 16vw; */
}

.header .homeLink img{    
    height: 3.5rem;
}

.WaduaColor
/* ,
.header .homeLink */
{
    margin-right: 30px;
    height: 68.5%;
}

.header .SM-container{
    display: flex;
    align-items: flex-start;
}
.back-header-movil{
    width: 100%;
    height: 190vh;
    background-color: #0a0a0a70;
    position: absolute;
    left: 0;
    /* z-index: 200; */
}


/** Querys */

@media  (min-width: 576px) {
    .header{
        max-width: 540px;
    }
}
@media  (min-width: 768px) {
    .header{
        max-width: 720px;
    }
}
@media  (min-width: 992px) {
    .header{
        max-width: 960px;
    }
}
@media  (min-width: 1200px) {
    .header{
        max-width: 1140px;
    }
}

@media  (min-width: 1400px) {
    .header{
        max-width: 1320px;
    }
}


