.Section4ABUC-desc{
    
    position: relative;
    display: flex;
    overflow: visible;
    justify-content: center;
    align-items: center;
    /* padding: 0px 11%; */
    
}

.ABUC4{
    background-image: url('/public/resources/Fotos/bannerEndPage.jpg');
    background-size: cover;
}

.btn-servc{
    height: auto;
    width: auto;
    position: relative;
    height: 15%;
    width: 60%;
    max-height: 400px;
    min-width: 246px;
    max-width: 246px;
    border-bottom-left-radius: 19px;
    border-top-right-radius: 19px;
}

.btn-s-pancho{
    position: absolute;
    height: auto;
    top: -173px;
    left: -29px;
    width: 300px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
}


