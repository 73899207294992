.carousel2{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.slides {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    margin-bottom: 15px;
}


.list {
    display: flex;
    /* width: 100%;
    height: 100%; */
    justify-content: center;
    padding: 0;
    list-style-type: none;
    gap: 20px;
    margin: 0px;
}

.navButtons {
    width: 24px;
    height: 24px;
    margin: 0px 10px;
    cursor: pointer;
}

.dotsControls {
    display: flex;
    text-align: center;
}

.navBtnArrow:hover{
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
}

.lateralPrevBtn{
    position: absolute;
    left: 18%;
    top: 38%;
    height: 7rem;
    width: 3rem;
    margin: 0px;
}
.lateralPrevBtn2{
    position: absolute;
    left: 17%;
    top: 15%;
    height: 17rem;
    width: 3rem;
    margin: 0px;
}

.lateralPostBtn{
    position: absolute;
    right: 18%;
    top: 38%;
    height: 7rem;
    width: 3rem;
    margin: 0px;
}

.lateralPostBtn2{
    position: absolute;
    right: 17%;
    top: 15%;
    height: 17rem;
    width: 3rem;
    margin: 0px;
}

/** Our Team Slider */

.list .img-team{
    width: 16rem;
    height: 16rem;
    border-radius: 50%;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.list .title-team
{
    font-family: 'Quicksand';
    color: #FFF;
    font-weight: bolder;
    margin: 0px;
}


 @media (min-width:0px) and (max-width: 768px){

    .lateralPrevBtn{
        left: 2%;
    }
    .lateralPostBtn{
        right: 2%;
    }
    .lateralPrevBtn2{
        left: 4%;
    }
    .lateralPostBtn2{
        right: 4%;
    }
}