.carousel-image-container{
    background-color: #F4F4F4;
    display: flex;
    align-items: center;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 11rem;
    height:13rem;
    padding: 6%;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 123px;
}

.carousel-image-container-img{
    width: 100%;
    height: 75%;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 3%;
}
.carousel-image-container-desc{
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.carousel-image-container .image-container-img{
    width: 100%;
    height: 100%;
    transform: scale(1);
    border-radius: 0%;
    border: 0px solid #FFF;
    box-shadow: none;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    -webkit-border-radius: 0%;
    -moz-border-radius: 0%;
    -ms-border-radius: 0%;
    -o-border-radius: 0%;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}

/** News Styles */

.news-style{
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 0%;
    width: 24%;
    height: auto;
    position: relative;
    padding: 2%; 
    
}

.news-style .title-news{
    max-height: 100px;
    margin: 12% 0;
    text-align: center;
}

.news-style .title-news .news-title{
    color: #054F57;
    overflow: hidden;
    font-family: 'Phenomena';
    font-size: 1.3vw; 
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;     /* fallback */
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    margin: 0px 10%;
}

.news-style img {
    width: 100%;
    height: 100%;
    max-height: 80%;
    
}

.news-style a{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/** Team styles */

.team-styles{
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20%;
    width: 90%;    
    height: auto;
}

.team-styles img{
    border-radius: 50%;
    max-height: 80%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

@media  (min-width: 0px) and (max-width: 430px) { 
    .carousel-image-container{
        width: 100vw;
        height:13rem;
        padding: 3rem 4.5rem; 
        min-width: 320px;
    }
}
@media  (min-width: 431px) and (max-width: 550px) { 
    .carousel-image-container{
        width: 100vw;
        height:17rem;
        padding: 3rem 7.5rem; 
    }
}

@media  (min-width: 551px) and (max-width: 768px) {     
    
    .carousel-image-container{
        width: 100vw;
        height:22rem;
        padding: 5rem 9rem; 
    }

    /* .carousel-image-container img{
        height: 100%;  
    } */

    .news-style{
        background-color: transparent;
        width: 100%;
        padding: 0px;
        height: auto;
        min-height: auto;   
    }
    
    .news-style .title-news{   
        margin: 5% 0;
    }
    
    .news-style .title-news .news-title{
        font-size: 6.3vw; 
        line-height: 137%;
    }
    
    .news-style img {
        border-bottom-left-radius: 0px;
        border-top-right-radius: 50px;   
        box-shadow: 0px 0px 19px -12px #979797;
    }
}

/* @media  (max-width: 426px){
    .carousel-image-container .image-container-img{
        width: 100%;
        height: 100%;
        transform: scale(0.5);
    }

    .carousel-her-cont {
        padding: 8vw 8vw !important;
    }
} */

@media  (min-width: 584px){
    /* .carousel-image-container{
        height: 48vh;
        padding: 5vw 20vw;
    } */

    .news-style{
        height: auto;
        min-height: auto;   
    }
}

@media (min-width: 769px) and (max-width: 1150px){
    .carousel-image-container{
        width: 10rem;
        height: 12rem;
    }
}


@media (min-width: 1151px) and (max-width: 1439px){
    .carousel-image-container{
        width: 10.2rem;
        height: 12.2rem;
    }
}


@media  (min-width: 769px) and (max-width: 1007px) {

    /* .carousel-image-container{
        height: 15vw;
        padding: 3%;
    } */
    .news-style {
        width: 45%;
    }

    .news-style{
        height: auto;
        min-height: auto;   
    }

    .news-style .title-news .news-title {
    font-size: 2.3vw;
    line-height: 19px;
    }
}

@media  (min-width: 1008px) {

    /* .carousel-image-container{
        height: 12vw;
        padding: 6%;
        min-width: 123px;
    } */

    /* .carousel-her-cont{
        padding: 2% !important;
    }
     */
    .news-style{
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 24%;
        height: auto;
        position: relative;
        padding: 2%; 
    }
    
}
@media (min-width: 1466px){
    .news-style .title-news .news-title {
        line-height: 18px;
        font-size: 1.2vw;
    }
}

@media (min-width: 1700px){
    .news-style .title-news .news-title {
        line-height: 20px;
        font-size: 1.1vw;
      }
}

@media (min-width: 1920px){
    .news-style .title-news .news-title {
        line-height: 21px;
        font-size: 1vw;
    }
    .news-style{
        height: 98%;
    }
}

@media (min-width: 2160px){
    .news-style .title-news .news-title {
        line-height: 25px;
      }
}

@media (min-width: 2560px){
    .news-style .title-news .news-title {
        line-height: 32px;
    }
    .news-style{
        height: 100%;
    }
}
