.modalOV{
    left: 0;
}

@media (min-width :0px) and (max-width: 1024px){
    .our-values{
        width: 100%;
        height: auto;
        /* padding: 4% 4% 4% 4%; */
        position: relative;
        overflow: hidden;
    }
    
    .ov-content {
        justify-content: center;
        row-gap: 4vw;
    }

    .ov-card{
        min-width: 101px;
    }
    .ov-card-p{
        text-align: start;
    }

    .ov-card-title{
        font-size: 3vw;
    }
        
}

@media (min-width: 425px) and (max-width: 600px){
    
    .ov-card-title{
        font-size: 
        3.1vw;
    }
 }

@media (max-width: 425px){
    
    .ov-card-title{
        font-size: 3.2vw;
    }
 }

@media (min-width :769px) and (max-width: 1007px){
    .our-values{
        width: 100%;
        height: auto;
        padding: 4% 21% 4% 13%;
        position: relative;
        overflow: hidden;
    }

    
    .ov-card-title{
        font-size: 2vw;
    }
}


/* *  backnd */

@media (min-width :0px) and (max-width: 425px){
    .our-values::before{
        height: 384vw;
    }
}
@media (min-width :426px) and (max-width: 546px){
    .our-values::before{
        height: 288vw;
    }
}