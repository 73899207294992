.socialButton{
    /*background-color: #AEB530;
    */display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem !important;
    width: 3rem;
    min-width: 3rem;
    /* min-width: 37px !important; */
    margin: 0px;
    /* margin-left: 3%; */
    overflow: hidden;
    border: 2px solid #116466;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    transform: perspective(1px) translateZ(0);
    -webkit-transform: perspective(1px) translateZ(0);
    -moz-transform: perspective(1px) translateZ(0);
    -ms-transform: perspective(1px) translateZ(0);
    -o-transform: perspective(1px) translateZ(0);
}
.socialButton a img{
    margin-top: 3px;
    width: 0.8rem;
    height: 0.8rem;
}
