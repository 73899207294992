.o-be-part{
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("/public/resources/Fondos/bannerEndPage.jpg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
}

.o-be-part .o-img{
    height: 100%;
    width: 20%;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.o-be-part .o-img a{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.o-be-part .o-img img{
    width: 80%;
}

@media  (max-width: 1024px) {
    .o-be-part .o-img img{
        width: 100%;
    }
}

@media  (max-width: 768px) {
    .o-be-part .o-img img{
        width: auto;
        height: 50%;
    }
}