.Section4ABUC-img{
    background: url(/public/resources/Fondos/panchoTexture.svg) #F4F4F4;
    width: 100%;
    height: 47vw;
    display: flex;
    justify-content: center;
    align-items: center;
}


.Section4ABUC-img::before{
    content: '';
    background: #FFF;
    width: 35vw;
    height: 35vw;
    position: absolute;
    border-radius: 50%;
    top: 5vw;
    left: 32%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.sloganS4{
    position: relative;
    width: 22vw;
    height: 22vw;
    z-index: 3;
    margin-top: 2vw;
    -webkit-filter: brightness(0) saturate(100%) invert(71%) sepia(50%) saturate(559%) hue-rotate(20deg) brightness(87%) contrast(93%);
            filter: brightness(0) saturate(100%) invert(71%) sepia(50%) saturate(559%) hue-rotate(20deg) brightness(87%) contrast(93%);

}
