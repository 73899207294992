.btns-mv{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    width: 100%;
    gap: 4%;
}

.btns-mv .btn-mv{
    margin: 8% 0%;
    width: 37%;
    height: 37vw;
    color: #008392;
    font-family: 'Phenomena';
    font-size: 6.7vw;
    font-weight: bold;
    background-color: #F4F4F4;
    border: none;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 5%;
    overflow: hidden;
    position: relative;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.btns-mv .btn-mv:first-child::before{
    content: "";
    background-image: url('/public/resources/Vectores/panchoBtn2.svg');
    width: 110%;
    height: 110%;
    position: absolute;
    top: -7%;
    left: -45%;
    z-index: 1;
}

.btns-mv .btn-mv:last-child::before{
    content: "";
    background-image: url('/public/resources/Vectores/panchoBtn1.svg');
    width: 110%;
    height: 110%;
    position: absolute;
    top: -7%;
    left: -45%;
    z-index: 1;
}

.btns-mv .btn-mv span{
    z-index: 3;
}

.btns-mv .btn-mv .icon-mv{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -42%;
    z-index: 1;
}

.btns-mv .btn-mv .detail-mv{
    color: #AEB530;
    /*background-color: #008392;
    */border: 1px solid #AEB530;
    border-radius: 70px;
    padding: 2% 9%;
    margin-top: 14%;
    font-size: 4vw;
    -webkit-border-radius: 70px;
    -moz-border-radius: 70px;
    -ms-border-radius: 70px;
    -o-border-radius: 70px;
}

.modalAUD{
    display: flex;
    position: fixed;
    background-color: #2d2d2d43;
    width: 100vw;
    height: 100vh;
    z-index: 15;
    top: 0px;
    align-items: center;
    justify-content: center;
    padding-top: 10%;
    min-width: 320px
}

.modalAUD .modalAUD-content{
    height: 70vh;
    width: 80vw;
    background:url(/public/resources/Fondos/textureGray.svg) #F4F4F4;
    border-radius: 20px;
    padding: 0% 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 20;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.modalAUD-title{
    color: #008392;
    font-family: 'Quicksand';
    font-weight: bold;
    background-color: #f4f4f48a;
}

.modalAUD-content-p{
    background-color: #f4f4f48a;
}

.btn-modal{
    position: absolute;
    right: 15px;
    top: 15px;
    width: 35px;
    height: 35px;
    border: none;
    background-color: transparent;
    color: #008392;
    font-weight: bolder;
    font-size: 100%;
}




@media  (min-width: 0px) and (max-width: 768px){

    .ABUC{
        flex-direction: column;
    }

    .about-us-C-desc{
        padding: 0 0px;
        height: auto;
        flex-direction: column;
        
    }

    .about-us-C-desc::before{        
        content: "";
        width: 150%;
        height: 64%;
        top: -25%;
        background-position-y: 50vw;
        left: -23%;
        background-size: 123vw;
        background-position-x: 2vw;
    }

    .about-us-C-desc  .max-width-container .abusc-img{
        width: 100%;
        height: 65vw;
        position: relative;   
        margin: 0px;
        padding: 6% 15% 15% 15%;
        min-height: 220px;
    }
    .about-us-C-desc  .max-width-container .abusc-img img{
        width: 100%;
        height: 100%;
        border-radius: 40% 40% 60% 60%;
        -webkit-border-radius: 40% 40% 60% 60%;
        -moz-border-radius: 40% 40% 60% 60%;
        -ms-border-radius: 40% 40% 60% 60%;
        -o-border-radius: 40% 40% 60% 60%;
}

    .about-us-C-desc  .max-width-container .abusc-content{
        width: 100%;
        height: 50%;
        padding: 0% 5% 5% 5%;
    }

    .about-us-C-desc  .max-width-container .abusc-content .abusc-content-title{
        color: #AEB530;
        /* font-size: 6.2vw; */
    }
    
}

@media  (min-width: 0px) and (max-width: 375px){
    .about-us-C-desc  .max-width-container .abusc-img{
        height: 72vw;
    }
}

@media  (min-width: 471px) and (max-width: 600px){
    .about-us-C-desc  .max-width-container .abusc-img{
        height: 57vw;
    }
}
@media  (min-width: 601px) and (max-width: 768px){
    .about-us-C-desc  .max-width-container .abusc-img{
        height: 52vw;
    }
}






/*Querys background*/

/* 
@media (min-width: 483px) and (max-width: 768px) {
    .about-us-C-desc::before{
        background-position-y: 20vw;
    }
}
@media (min-width: 554px) and (max-width: 768px) {
    .about-us-C-desc::before{
        background-position-y: 17vw;
    }
}

@media  (min-width: 339px) and (max-width: 768px) {
    .about-us-C-desc::before{        
        content: "";
        background-position-y: 42vw;
        background-size: 123vw;
        background-position-x: 2vw;
    }
}
@media  (min-width: 471px) and (max-width: 768px) {
    .about-us-C-desc::before{        
        content: "";
        background-position-y: 33vw;
        background-size: 123vw;
        background-position-x: 2vw;
    }
}
@media  (min-width: 769px)  and (max-width: 1007px) {
    .about-us-C-desc::before{        
        content: "";
        background-position-y: 25vw;
        background-size: 79vw;
        background-position-x: -9vw;
    }
}
@media  (min-width: 812px)  and (max-width: 1024px) {
    .about-us-C-desc::before{        
        content: "";
        background-position-y: 23vw;
        background-size: 76vw;
        background-position-x: -8vw;
    }
}
@media  (min-width: 891px) and (max-width: 1052px) {
    .about-us-C-desc::before{        
        content: "";
        background-position-y: 19vw;
        background-size: 75vw;
        background-position-x: -8vw;
    }
}
@media  (min-width: 1052px) and (max-width: 1200px) {
    .about-us-C-desc::before{        
        content: "";
        background-position-y: 16vw;
        background-size: 75vw;
        background-position-x: -8vw;
    }
}
@media  (min-width: 1600px) {
    .about-us-C-desc::before{        
        content: "";
        background-position-y: 12vw;
        background-size: 66vw;
        background-position-x: 2vw;
    }
}
@media  (min-width: 1724px) {
    .about-us-C-desc::before{        
        content: "";
        background-position-y: 10vw;
        background-size: 63vw;
        background-position-x: 4vw;
    }
}
@media  (min-width: 2024px) {
    .about-us-C-desc::before{        
        content: "";
        background-position-y: 10vw;
        background-size: 63vw;
        background-position-x: 4vw;
    }
}
@media  (min-width: 2124px) {
    .about-us-C-desc::before{        
        content: "";
        background-position-y: 8vw;
        background-size: 56vw;
        background-position-x: 9vw;
    }
} */

@media (min-width: 0px) and (max-width: 768px) {
    .about-us-C-desc .abusc-img::before{
        border-radius: 0% !important;
        left: -17% !important;
        bottom: -21px !important;
        background-size: 90% !important;
        -webkit-border-radius: 0% !important;
        -moz-border-radius: 0% !important;
        -ms-border-radius: 0% !important;
        -o-border-radius: 0% !important;
}
}
@media (min-width: 769px) and (max-width: 1030px) {
    .about-us-C-desc .abusc-img::before{
        background-position-y: 2.5rem !important;
        background-size: 39rem !important;
    }
}
@media  (min-width: 2560px) {
    .about-us-C-desc .abusc-img::before{
        background-position-y: 3rem !important;
        background-size: 110% !important;
    }
}