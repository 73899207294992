 /* * Carousel styles */

 @media (min-width: 0px) and (max-width: 769px){
    .card-Service .image-carosuel-service {
        height: 10rem;
    }
    
    .list .category-secnd{
        width: 7rem;
        height: 7rem;
    }

    .list .category-secnd .img-team{
        width: 7rem;
        height: 7rem;
    }

 }
    