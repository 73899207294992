.language{
    margin: 2px;
}

.language-btn{
    background-color: transparent;
    border: none;
    color: #008392;
    font-weight: bolder;
}

.language-header{
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}


.language-img{
    cursor: pointer;
    /*-webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    */width: auto;
    height: 100%;
    transform: scale(4.5);
    -webkit-transform: scale(4.5);
    -moz-transform: scale(4.5);
    -ms-transform: scale(4.5);
    -o-transform: scale(4.5);
}