.menu{
    display: flex;  
    width: 100%;
}

.menu a,.menu span{
    position: relative;
    color: rgba(0, 0, 0, 0.5);
    /* color: #979797; */
    /* font-weight: bold; */
    text-decoration: none;
    transition:  0.5s ease-out;
    font-family: 'Quicksand';
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}



/* .menu a:hover
,.menu span:hover{
    color: rgba(0, 0, 0, 0.7);
} */

/* .menu a::before,.menu span::before{
    content: '';    
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color:  #008392;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.menu a:hover::before, .menu span:hover::before {    
    transform: scaleX(1);
  } */

.menu ul{
    padding: 0px 5% 0px 1.1%;
    display: flex;
    width: 100%;
    list-style: none; 
    position: relatives;   
}

.menu ul li{
    position: relative;
    /* z-index: 201; */
    /* padding:9px 14px; */
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.menu .isHeader {      
    /* font-size: 23px;
    gap: 5%; */
    /* font-size: 18px; */
    font-size: 1.125rem;
    gap: 1%;
    
}

.menu .noHeader {   
    gap: 1%;
    font-size: 1.5vw;      
}

/* * Menu-des styles */

.menu .internal-nav{
    position: absolute;
    background-color: #ffffff;
    /* z-index: 202; */
    width: auto;
    height: auto;
    top: -10px;
    left: -10px;
    display: none;
    border: 1px solid #80808080;
    flex-direction: column;
    /*padding: 50% 25% 10% 25%;
    */    
    padding: 2% 10px 2% 12px;
    margin-top: 34%;
    align-items: flex-start;
    /*font-size: 1.5vw;
    /* font-size: 18px; */

    font-size: 1.125rem;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.menu .internal-nav li{
    /* z-index: 204; */
    margin: 7px 0px;
}

.menu .internal-nav li a{
    color: #AEB530;
}

.menu .internal-nav li a:hover{
    color: #AEB530;
}

.menu .internal-nav li:hover{
    background-color: #29292910;
}

.menu .internal-nav li a:hover::before{
    background-color:  #AEB530;
}

.activo{
    background-color: #116466;
    color: #ffffff !important;    
}
.activo a,
.activo span{
    color: #ffffff !important;
}
/* a:not(.is-active) {
} */

.menu .dropdown:hover .internal-nav{
    display: flex;
        /* -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
                animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both; */
    
}
.prueba {
    display: none;
}

.menu .dropdown:focus
.prueba {
    display: flex;
}

.about-us-C-desc .abusc-img img{
    width: 100%;
    height: auto;
}


.last-li{
    margin-top: 1.4rem;
    margin-left: 1.5rem;
}


@media  (min-width: 769px) {
    .menu ul li a{
        padding: 0.4rem 1rem;
        border-radius: 0.25rem;
    }
    .know-us{
        padding: 23px 0px;
    }
}

@media  (max-width: 1023px) {
    .menu .noHeader{
        font-size: 1.125rem;
    }
}

@media  (min-width: 1640px) {
    .menu .isHeader {      
        font-size: 1.125rem;
        
    }    
    .menu .internal-nav {      
        font-size: 1.125rem;
        
    }    
    .menu .noHeader {   
        gap: 6.5%;
        font-size: 1.2vw;      
    }
}





/*
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
*/
@-webkit-keyframes swing-in-top-fwd {
    0% {
        transform: rotateX(-100deg);
        transform-origin: top;
        opacity: 0;
    }
    100% {
        transform: rotateX(0deg);
        transform-origin: top;
        opacity: 1;
    }
}
@keyframes swing-in-top-fwd {
    0% {
        transform: rotateX(-100deg);
        transform-origin: top;
        opacity: 0;
    }
    100% {
        transform: rotateX(0deg);
        transform-origin: top;
        opacity: 1;
    }
}

