@font-face {
  font-family: 'Phenomena';
  src: url('./fonts/Phenomena-Regular.otf');
  font-weight: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./fonts/Quicksand-VariableFont_wght.ttf');
  font-weight: normal;
}

@-ms-viewport{
  zoom: 1.0;
  width: device-width;
}

@-o-viewport{
  zoom: 1.0;
  width: device-width;
}

@viewport{
  zoom: 1.0;
  width: device-width;
}

*{
  box-sizing: border-box;
}

body {
  margin: 0px !important;
  padding: 0px !important; 
  width: 100vw; 
  background-color: #F4F4F4;
  font-family: 'Phenomena',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    max-width: 100%;
}

code {
  font-family: 'Phenomena',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.disable{
  display: none !important;
}

a, a:hover, a:active, a:visited, a:focus {
  text-decoration:none;
}

/* *Title general Styles */

.title-h2{
  font-size: 2rem;
  font-family: 'Quicksand';
  /* margin: 0px 0px 25px 0px; */
}

.general-title-h1{
  font-weight: bolder;
  /* font-size: 2em; */
  margin-bottom: 10px;
}

.general-title-h2{
  font-weight: bolder;
  /* font-size: 2em; */
  margin-bottom: 10px;
}


@media (min-width: 0px){
  .general-title-h2{
    font-size: 1.9rem;
  }
  
  .general-title-h3{
    font-size: 1.1rem;
  }

  .general-title-h1{
    font-size: 2.6rem;
  }
}
@media (min-width: 375px){  
  .general-title-h3{
    font-size: 1.4rem;
  }
}
 @media (min-width: 500px){
  .general-title-h2{
    font-size: 2.2rem;
  }

  .general-title-h3{
    font-size: 1.6rem;
  }

  .general-title-h1{
    font-size: 2.8rem;
  }
}

/* *P General Styles */

p{
  font-size: 1rem;
  text-align: justify;
  font-family: 'Quicksand';
  color: #054F57;;
}

@media  (min-width: 0px) and (max-width: 1023px) {
  p{  
    font-size: 0.9rem;
  }
}

@media  (min-width: 1024px) {

p{
    font-size: 1rem;
}

}

@media  (min-width: 1440px) {

p{
  font-size: 1.1rem;
}
}

/* * Sections General Styles */

  .Section{  
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    min-height: 400px;
    min-width: 990px;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }    

  @media  (min-width: 0px) and (max-width: 1023px) {
    .Section{  
      min-width: 320px !important;
    }
  }
  @media  (min-width: 1024px){
    .Section{ 
      height: 400px;
    }
  } 
  @media  (min-width: 1200px){
    .Section{
      padding: 0px !important;
    } 
  }  
  @media  (min-width: 1440px){
    .Section{ 
      height: 450px;
    }
  } 
  @media  (min-width: 1920px){
    .Section{ 
      height: 500px;
    }
  } 
  @media  (min-width: 2560px){
    .Section{ 
      height: 600px;
    }
  } 

  /*Inner container*/

  .max-width-container{
    width: 100%;
    max-width: 1320px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media  (min-width: 0px) and (max-width: 768px){
    .max-width-container{
        position: relative;
        padding: 0 0%;
        display: flex;
        flex-direction: column-reverse;
    } 
  } 

  @media  (min-width: 769px) and (max-width: 1199px){
    .max-width-container{
      max-width: 100%;
    } 
  } 

  @media  (min-width: 1290px){
    .max-width-container{
      overflow: hidden;
      /* border-radius: 1.5rem; */
      /* -webkit-border-radius: 1.5rem;
      -moz-border-radius: 1.5rem;
      -ms-border-radius: 1.5rem;
      -o-border-radius: 1.5rem; */
} 
  }

  @media  (min-width: 1290px) and (max-width: 1399px){
    .max-width-container{
      max-width: 1140px;
    } 
  } 

  @media  (min-width: 1400px) {
    .max-width-container{
      max-width: 1320px;
    } 
  } 
  

/** End Sections General Styles*/

/** Backgrounds color */

.blue{
  background-color:#008392; 
}

.gray{
  background-color:#F4F4F4; 
}

.white{
  background-color:#FFF; 
}

.green{
  background-color:#4b254b; 
}