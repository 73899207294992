.bannerS{
    /* background-color: #054f57; */
    margin: 0 0 10px 0;
}

.slick-dots{
    padding: 30px;
}

.title_banner{
    margin: 20px;
    margin-left: 15%;
    color: #AEB530;
    font-size: 2.2rem;
    font-weight: bolder;
}