.Section3ABUC-desc{
    /* background-color: #FFF; */
    position: relative;
    overflow: hidden;
    /* padding: 0 34px 0 0px; */
    z-index: 1;
    height: auto;
}

.ABUC3{
    display: flex;
    flex-direction: column;
    background: url("../../../../public/resources/Fondos/aubckgnd.svg"), #FFFFFF90;
    background-blend-mode: overlay;
    background-position: bottom;
    background-repeat: no-repeat;
    /* padding: 0px 3em; */
    /* padding: 2em 2em 2em 2em; */
}

/* .info-team{
    margin-bottom: 0px;
} */


.Section3ABUC-team{
    width: 100%;
    height: 100%;    
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* .Section3ABUC-team::before{
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
    border-bottom-right-radius: 40%;
    background-color: #F4F4F4;
    z-index: -1;
} */

/* .Section3ABUC-team .Section3ABUC-team-title{
    position: relative;
    overflow: hidden;
    background-color: #008392;
    width: 44%;
    height: 17%;
    margin: 0px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 0% 0% 15px 15px;
    color: #FFF;
    -webkit-border-radius: 0% 0% 15px 15px;
    -moz-border-radius: 0% 0% 15px 15px;
    -ms-border-radius: 0% 0% 15px 15px;
    -o-border-radius: 0% 0% 15px 15px;
}


.Section3ABUC-team .Section3ABUC-team-title::before{
    content: "";
    margin: 0px;
    display: block;
    position: absolute;
    width: 52%;
    max-width: 130px;
    height: 213%;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    box-shadow: -52px 2vw 0 0 #aeb530;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
} */

.Section3ABUC-team .Section3ABUC-team-content{
    width: 100%;
    height: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 6%; */
    position: relative;
}

.Section3ABUC-content{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3em 0 4% 0;
    
}

.Section3ABUC-content .Section3ABUC-content-title{
    color: #008392;
    margin-top: 0px;
    margin-bottom: 2%;
}

/* @media (min-width:1024px) and (max-width:1357px){
    .Section3ABUC-content {
        width: 37%;
    }
    .Section3ABUC-team {
        width: 63%;
    }
} */



