.about-us-C-desc{
    display: flex;
    /* padding: 0 34px; */
    position: relative;
    min-height: 100%;
}

@media  (min-width: 1640px) {
    .about-us-C-desc{
        padding: 0px 4% ; 
    }
}

@media  (min-width: 1920px) {
    .about-us-C-desc{
        padding: 0px 6%;
    }
}

.about-us-C-desc .max-width-container .abusc-img{
    width: 50%;
    height: 100%;
    position: relative;    
    padding: 0px;
    margin: 0px;
    overflow: hidden;
}

.about-us-C-desc .max-width-container .abusc-img::before{
    content: "";
    margin: 0px;
    display: block;
    position: absolute;
    width: 127%;
    height: 124%;
    top: -9%x;
    left: -27%;
    bottom: -14%;
    border-radius: 50%;
    background: url(/public/resources/Fotos/waduateam.png) #aeb530;
    background-position: center;
    background-position-y: 2rem;
    background-repeat: no-repeat;
    background-size: 100%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.about-us-C-desc .max-width-container .abusc-content{
    width: 50%;
    height: auto;
    padding: 1% 4% 0% 11%;
}

.about-us-C-desc .max-width-container .abusc-content .abusc-content-btns{
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    bottom: 0vw;
    height: 5vw;
    gap: 3%;
}

.about-us-C-desc .max-width-container .abusc-content .abusc-content-btns button{
    width: 50%;
    height: 47px;
    width: 50%;
    max-height: 184px;
    min-width: 130px;
    max-width: 188px;

}

.about-us-C-desc .max-width-container .abusc-content .abusc-content-title{
    color: #FFFFFF;
}

.about-us-C-desc .max-width-container .abusc-content p{
    color: #f4f4f4;
}










