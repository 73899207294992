.IFrame{
    width:100% !important;
    height: 100vh !important;
    border: none !important; 
    max-width:100% !important; 
    max-height:100% !important;
    background-color: transparent !important;
}

.IFrame body{
    --solid-background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.5) 100%);
}

.-cP-103{
    display: none !important;
}