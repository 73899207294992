
/* *Section 3 image */

@media (max-width: 1023px) {

    .o-Section2_carousel{
        height: auto;
    }
    
    .o-Section2-Cont_serv{
        width: 100%;
        margin: 0;
    }
}

@media  (min-width: 1640px) {
    #Section3{
        padding: 0px 4% ; 
    }
}

@media  (min-width: 1920px) {

    #Section3{
        padding: 0px 6%;
    }
}