.o-values {
  width: 100%;
  display: flex;
  padding: 0 0 1% 0;
  /* background-color: #008392; */
  background-image: url("/public/resources/Vectores/Recursos-11.svg");
  background-repeat: no-repeat;
  background-position: center;
  font-family: "Quicksand";
}

.o-values .o-targets {
  width: 50%;
  height: 100%;
  padding: 5vw 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

/*---------------------*/
.o-values .o-content {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  padding: 0 15% 0 2%;
}

.o-values .o-content .o-content-title {
  color: #ffffff;
  /* font-size: 3vw; */
  margin: 0;
  text-align: left;
}

.o-values .o-content .o-content-desc {
  color: white;
}

.o-values .o-content .o-content-info {
  font-weight: bold;
  letter-spacing: 2px;
  background-color: rgba(255, 255, 255, 1);
  padding: 3%;
  border-radius: 0px 20px 0px 20px;
  -webkit-border-radius: 0px 20px 0px 20px;
  -moz-border-radius: 0px 20px 0px 20px;
  -ms-border-radius: 0px 20px 0px 20px;
  -o-border-radius: 0px 20px 0px 20px;
}

.o-values .o-content p {
  color: #AEB530;
  /* font-family: "Quicksand"; */
  /* font-size: 1.1vw; */
}


  .o-values .o-targets {
    width: 100%;
  background-image: url("/public/resources/Vectores/Recursos-11.svg");
  background-repeat: no-repeat;
  background-position: left;
  }

  /*---------------------*/
  .o-values .o-content {
    width: 100%;
    padding: 5vw 10%;
  }

  .o-values .o-content .o-content-title {
    color: #ffffff;
    /* font-family: "Quicksand";
    font-size: 6vw; */
    margin: 0;
    text-align: left;
  }

  .o-values .o-content .o-content-info {
    font-weight: bold;
    letter-spacing: 0px;
    background-color: rgba(255, 255, 255, 1);
    padding: 2%;
    border-radius: 0px 20px 0px 20px;
    color: #AEB530;
    -webkit-border-radius: 0px 20px 0px 20px;
    -moz-border-radius: 0px 20px 0px 20px;
    -ms-border-radius: 0px 20px 0px 20px;
    -o-border-radius: 0px 20px 0px 20px;
}

  .o-values .o-content p {
    /* font-size: 4vw; */
  }


@media (max-width: 768px) {
  .o-values {
    flex-direction: column-reverse;
    height: auto !important;
    background-image: none;
  }
}


@media (min-width: 1920px) {
  .o-img img {
    width: 60%;
  }
}
