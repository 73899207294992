.o-serv-s2{
  height: auto;
}

.o-serv-s2 .OSS2{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
