.isMovilHed{
    height: 100%;
    align-content: space-between;
    flex-wrap: wrap;
    padding-top: 0.625rem;
    padding-right: calc(1rem + 15px);
    padding-bottom: 0.625rem;
    padding-left: calc(1rem + 15px);
}
.header-menu{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    width: 80px;
    right: 0.5%;
    z-index: 200;   
}
.header-menu img{
    width: 55%;  
}

@media (max-width: 767.98px){
    .isMovilHed .homeLink img{
        max-height: 3.5rem;
    }
}

@media  (min-width: 0px) and (max-width: 553px) {
    #header{
        height: 70px;
        /* padding: 0px 7%;  */
        min-width: 320px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }

    .WaduaColor{
        margin-right: 0px;
        /* width: 35%; */
        height: auto;
    }

    .header-menu img{
        width: 40%;
    }
}

@media  (min-width: 0px) and (max-width: 1007px) {
    #header{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }
}




