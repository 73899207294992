.o-culture {
  display: flex;
  /* padding: 0 0 1% 0; */
  /* background-color: #008392; */
  font-family: "Quicksand";
}

.o-culture .o-content {
  width: 55%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  padding: 0 10%;
  background-color: #ffffff;
  background-image: url("/public/resources/Fondos/textureGray.svg");
  border-radius: 0% 0% 0% 100% / 0% 0% 0% 10%;
  -webkit-border-radius: 0% 0% 0% 100% / 0% 0% 0% 10%;
  -moz-border-radius: 0% 0% 0% 100% / 0% 0% 0% 10%;
  -ms-border-radius: 0% 0% 0% 100% / 0% 0% 0% 10%;
  -o-border-radius: 0% 0% 0% 100% / 0% 0% 0% 10%;
}

.o-culture .o-content .o-content-title {
  color: #48c0b4;
  /* font-family: "Quicksand";
  font-size: 4vw; */
  margin: 0;
}

.o-culture .o-content .o-content-desc {
  /* padding: 5%; */
}

.o-culture .o-content p {
  color: #054f57;
  /* font-family: "Quicksand";
  font-size: 1.3vw; */
}

.o-culture .o-content .o-content-subtitle {
  color: #054f57;
  /* font-family: "Quicksand";
  font-size: 1.3vw;
  font-weight: bold; */
  margin: 0;
}

.o-culture .o-img {
  width: 45%;
  height: 100%;
  padding: 1% 11% 0% 11%;
  background-color: #08b5c9;
  position: relative;
  background-image: url("/public/resources/Fotos/Wadua3.png");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0% 0% 100% 0% / 0% 0% 10% 0%;
  -webkit-border-radius: 0% 0% 100% 0% / 0% 0% 10% 0%;
  -moz-border-radius: 0% 0% 100% 0% / 0% 0% 10% 0%;
  -ms-border-radius: 0% 0% 100% 0% / 0% 0% 10% 0%;
  -o-border-radius: 0% 0% 100% 0% / 0% 0% 10% 0%;
}

.o-culture .o-img img {
  width: 80%;
  position: absolute;
  right: -10%;
  bottom: -4%;
  -o-object-fit: contain;
     object-fit: contain;
  z-index: 3;
}

@media (max-width: 768px) {
  .o-culture {
    height: 650px;
    flex-direction: column-reverse;
    background-color: white;
  }

  .o-culture .o-img img {
    width: 60%;
  }

  .o-culture .o-content {
    width: 100%;
    border-radius: 0;
    height: 60%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}

  .o-culture .o-content .o-content-title {
    /* font-size: 5vw; */
  }

  .o-culture .o-content .o-content-desc {
    padding: 0;
  }
  
  .o-culture .o-content p {
    /* font-size: 2.5vw; */
    font-weight: 500;
  }

  .o-culture .o-content .o-content-subtitle {
    /* font-size: 3.5vw; */
  }

  .o-culture .o-img {
    width: 100%;
    height: 40%;
    border-radius: 100% 0% 100% 0% / 0% 58% 42% 100% ;
    -webkit-border-radius: 100% 0% 100% 0% / 0% 58% 42% 100% ;
    -moz-border-radius: 100% 0% 100% 0% / 0% 58% 42% 100% ;
    -ms-border-radius: 100% 0% 100% 0% / 0% 58% 42% 100% ;
    -o-border-radius: 100% 0% 100% 0% / 0% 58% 42% 100% ;
}
  
  .o-culture .o-img img {
    height: 90%;
  }
  
}

@media (min-width: 1440px) {
  .o-culture {
    height: 550px;
  }

  .o-culture .o-img img {
    width: 60%;
  }
}

@media (min-width: 1920px) {
  .o-culture .o-img img {
    width: 60%;
  }
}
