/* @media  (min-width: 0px) and (max-width: 768px){
    .category-teamMembers-img{
        margin-top: 20px;     
    }
}

@media (min-width:0px) and (max-width:531px){
    .category-teamMembers-img{
        margin-top: 20px;
        width: 40vw;
        height: 40vw;     
    }
}
@media (min-width:532px) and (max-width:768px){
    .category-teamMembers-img{
        width: 30vw;
        height: 30vw;     
    }
}
@media (min-width:769px) and (max-width:1007px){
    .category-teamMembers{
        width: 20vw;
    }
    .category-teamMembers-img{
        width: 17vw;
        height: 17vw;     
    }
} */

@media (min-width:0px) and (max-width: 555px){
    .slider-services, .carousel-team {
        width: 100%;
    }
}

