/* *General Styles */

#Section2{
    position: relative;
    /* background-color:#008392;     */
    /* padding: 0 0 0 34px; */
    overflow: hidden;
}

#Section2::after{
    content: "";
    background-color: #F4F4F4;
    position: absolute;
    right: 0px;
    top: -16px;
    width: 100%;
    height: 8%;
    border-radius: 0% 0% 100% 100%;
    z-index: 1;
    -webkit-border-radius: 0% 0% 100% 100%;
    -moz-border-radius: 0% 0% 100% 100%;
    -ms-border-radius: 0% 0% 100% 100%;
    -o-border-radius: 0% 0% 100% 100%;
}

@media  (min-width: 1640px) {
    #Section2{
        padding: 0px 0px 0px 4% ; 
    }
}

@media  (min-width: 1920px) {
    #Section2{
        padding: 0px 0px 0px 6%;
    }
}

.phraseTmp a{
    text-decoration: none;
    color: #FFF;
}
.phraseTmp a:visited{
    text-decoration: none;
    color: #FFF;
}

/* *Section 4 image */

.S2-image_mision{
    height: 100%;
    width: 50%;
    overflow: hidden;
    background: linear-gradient(0.75turn, #4b254b, rgba(255, 255, 0, 0)), url('/public/resources/Fotos/FotoEquipo.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.line{
    background-color: #AEB530;
    width: 1px; 
    height: 80%;
}

/* *Section 2 content */
.S2-content{
    display: flex;
    height: 100%;
    width: 50%;
}

.S2-content .S2-content-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-family: 'Quicksand';
    padding: 6% 22% 0% 22%;
}

.S2-content .S2-content-info h2{
    color: #FFFFFF;
    margin: 0px 0px 15px 0px;
}

.S2-content .S2-content-info p{
    text-align: justify;
    margin: 0px 0px 10px 0px;
    color: #FFF;
}

.S2-content .S2-content-info .S2-Btn{
    height: 13%;
    width: 50%;
    max-height: 184px;
    min-width: 154.4px;
    max-width: 188px;
}

.S2-content .S2-content-info .phrase{
    font-family: 'Quicksand';
    text-align: justify;
    margin: 0px 0px 25px 0px;
    color: #FFF;
    font-weight: bold;
}
.S2-content .S2-content-info .phraseTmp{
    text-align: start;
    font-style: italic;
    font-weight: normal;
}