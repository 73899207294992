.o-Section3{
    /* background-color:#ffffff;  */
    height: auto;
    padding: 0 34px;
    display: flex;
    flex-direction: column;
}

.o-slider{
    width: 100%;
    height: 80%;
    padding: 2rem 0px 0px 0px;
    /* overflow: hidden; */
}

/* *Section 3 image */

.o-Section-Cont .o-carousel-title{
    font-family: 'Quicksand';
    /* font-size: 4vw; */
    color: #00C0B4;
    margin: 0px;
    align-self: start;
    margin-bottom: 18px;
}

@media (max-width: 1220px) {
    
    .o-Section3{
        /* height: auto; */
        padding: 0px;
    }

    /* .o-Section-Cont .o-carousel-title{
        margin: 5vw 0px !important;
    } */
}

@media (min-width: 769px) and (max-width: 1439px) {
    .o-slider {
        padding: 2rem 0px 0px 0px !important;
    }
}

@media  (min-width: 1640px) {
    #Section3{
        padding: 0px 4% ; 
    }
}

@media  (min-width: 1920px) {

    #Section3{
        padding: 0px 6%;
    }
}