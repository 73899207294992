.our-differnces{
    overflow: inherit; 
    position: relative; 
}

.our-differnces .OURD{
    padding-right: 13%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    position: relative;
    
}

.our-differnces .OURD::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #AEB530;
    background: url(/public/resources/Fondos/textureServices.svg);
    background-repeat: no-repeat;
    background-position: bottom left 79%;
    background-size: 52vw;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
}
.od-content{
    width: 50%;
    position: relative;
    z-index: 6;
}

.od-content-title{
    text-align: start;
    font-family: 'Quicksand';
    font-weight: bolder;
    color: #fff;
}

.od-content-p{
    color: #fff;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
}

.od-img{
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    /* width: 45rem; */
    position: absolute;
    left: calc(16rem - 2vw);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    overflow: inherit;
}

.od-img img{   
    position: absolute;
    width: 17vw;
    height: auto;
    top: -9em;
    left: -8em;
}


/* * Querys */

@media (min-width: 769px){
    .od-img img{
        top: -6em;
        left: -5em;
        width: 25%;
    }
}
/* @media (min-width: 900px){
    .od-img img{
        width:70%;
    }
}
@media (min-width: 1000px){
    .od-img img{
        width: 67%;
    }
}
@media (min-width: 1024px){
    .od-img img{
        width: 65%;
    }
}
@media (min-width: 1224px){
    .od-img img{
        width: 60%;
    }
}
@media (min-width: 1624px){
    .od-img img{
        width: 55%;
    }
}
@media (min-width: 2000px){
    .od-img img{
        width: 52%;
    }
}
@media (min-width: 2150px){
    .od-img img{
        width: 50%;
    }
}
@media (min-width: 2300px){
    .od-img img{
        width: 47%;
    }
} */