/* @media  (min-width: 0px) and (max-width: 1030px){ */

    .Section3ABUC-desc{
        flex-direction: column;
        height: auto;
        /* background-color: #FFF; */
        position: relative;
        overflow: hidden;
        padding: 0 0px 0 0px;
    }

    .Section3ABUC-team{
        height: 50%;
        width: 100%;
    }

    .Section3ABUC-team .Section3ABUC-team-title{
        position: relative;
        overflow: hidden;
        background-color: #008392;
        width: 50%;
        height: 12vw;
        margin: 0px;
        align-items: center;
        display: flex;
        justify-content: center;
        border-radius: 0% 0% 15px 15px;
        color: #FFF;
        font-size: 6.7vw;
        -webkit-border-radius: 0% 0% 15px 15px;
        -moz-border-radius: 0% 0% 15px 15px;
        -ms-border-radius: 0% 0% 15px 15px;
        -o-border-radius: 0% 0% 15px 15px;
}

    .Section3ABUC-team .Section3ABUC-team-content{
    width: 100%;
    /* height: 83%; */
    display: flex;
    /* padding: 0% 5%; */
    justify-content: center;
    align-items: center;
    /* gap: 6%; */
    position: relative;
    }

    .Section3ABUC-content{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0% 5%;
        
    }

    .Section3ABUC-content .Section3ABUC-content-title{
        color: #48C0B4;
        margin-top: 0px;
        margin-top: 9%;
        margin-bottom: 0%;
    }

    .Section3ABUC-content-desc{
        margin-bottom: 10vw;
    }

    .Section3ABUC-team .Section3ABUC-team-title::before{
        box-shadow: -20px 2vw 0 0 #aeb530;
    }
/* }

@media  (min-width: 426px) and (max-width: 1030px){
    .Section3ABUC-team .Section3ABUC-team-title{
        height: 11vw;
        margin-bottom: 3%;
    }

    .Section3ABUC-team .Section3ABUC-team-title::before{
        box-shadow: -33px 2vw 0 0 #aeb530;
    }
}
 */


 @media (min-width:0px) and (max-width: 555px){
    /* .Section3ABUC-team {
        height: 100%;
        width: 100%;
    } */

    .Section3ABUC-team .Section3ABUC-team-content {
        flex-direction: column;
        height: 100%;
    }
 }
