/* ! NOTE: Querys order asc */

/* * _____________________________________________General styles */
.footer{
    background-color: #054F57;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    font-family: 'Quicksand';
}

/* *  ____________________________________________Content styles */

.footer .content{
    background-color: #F6F4F2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 15px;
}

.footer .content .footer-internal-container{
    display: grid !important;
    grid-auto-rows: 50px;
    grid-template-columns: repeat(12, 1fr);
    row-gap: 0px;
    -moz-column-gap: 0px;
         column-gap: 0px;
}
.footer .content .footer-internal-container >div,
.logo-footer, .desc-footer{
    padding: 10px;
}

.footer-internal-container-B{
    display: flex;
    justify-content: flex-start;
}

/* * _________Section 1 styles */

.logo-footer{
    grid-area: 2 / 1 / 4 / 3;
    width: 100%;
}

.desc-footer{
    grid-area: 2 / 3 / 4 / 7;
}

/* End Section 1 styles*/

/* *_________Section2 Styles */

    .footer .content .section2{
        grid-area: 1 / 7 / 4 / 9;
        border: 1px solid #dee2e6 !important;
        border-color: rgba(222, 226, 230, 0) !important;
        border-width: 13px !important;
        -webkit-box-flex: 0;
    }
     .footer .content .section2 p{
        margin-bottom: 1rem !important;
        margin-top: 0px;
    }
     .footer .content .section2 p span{
        font-size: 14px;
    }
     .footer .content .section2 p span strong{
        font-weight: bolder;
        color: #116466 ;
        font-weight: bolder;
    }
    
    .footer .content .section2 span a{
        color: #008392;
        text-decoration: none;
    }

    .footer .content .section2 span a:visited{
        color: #008392;
        text-decoration: none;
    }

    .footer .content .section2 h3{
        color: #AEB530;
        font-family: 'Quicksand';
        text-align: initial;
        font-weight: bold;
        margin-bottom: 0.5rem;
        font-weight: 600;
        line-height: 1.1;
    } 

    .SM-footer{
        grid-area: 3 / 7 / 6 / 9;
    }

    .SM-footer .SM-icons{
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 0.8rem;
        margin-top: 1.6rem;
    }

   
/*End Section2 Styles*/

/* *_________Section3 Styles */

.footer .content .section3{
    grid-area: 2 / 10 / 4 / 12;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.footer .content .section3 p{
    margin-top: 0px;    
}
.footer .content .section3 p span a{
    color: #116466; 
    font-size: 0.9rem;
    font-weight: bold;
}


/* * ________________________________________________________Botom styles */

.footer .bottom{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    /* height: 27%; */
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.footer .bottom .bottom-text{
    color: rgba(255, 255, 255, 0.7) ;
    /* font-size: 21px; */
    z-index: 3;
}