@media  (min-width: 0px) and (max-width: 768px) {
    #Section1 {
        position: relative;
        overflow: inherit;
        padding: 0 0;
        align-items: flex-start;
        height: auto;
    }
    .S1-Logo {       
        position: absolute;
        top: -10%;
        left: -11%;
        width: 45vw;
        height: 45vw;
        z-index: 6;
    }

    .S1-Logo img {
        position: static;
        width: 100%;
        height: 100%;
    }

    .S1-image {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
    }

    .S1-image .S1-carousel-title {
    margin:  7% 10% 0px 10%;
    text-align: end;
    margin-bottom: 5%;
    }
}
@media  (min-width: 426px) and (max-width: 768px) {
    .S1-Logo {       
        position: absolute;
        top: -10%;
        left: -11%;
        width: 40vw;
        height: 40vw;
        z-index: 6;
    }
}