#Section1{
    /* background-color:#F5F5F5;   */
    padding: 0 34px 0 0 ;
    overflow: hidden;
}

/* *Section 3 content */
.S1-Logo{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30%;
    position: relative;
}

.S1-Logo img{
    position: relative;
    left: -27%;
    width: 100%;
    height: 100%;
}

/* *Section 3 image */

.S1-image{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 70%;
    text-align: left;
}

.S1-image .S1-carousel-title{   
    font-family: 'Quicksand';
    color: #AEB530;
    margin: 0px 2%;
}

.S1-image .carousel-container{
    width: 100%;
    margin-top: 15px;
    height: 60%;
    margin: 12px auto;
}

@media  (min-width: 1640px) {
    #Section1{
        padding: 0px 4% ; 
    }
    .S1-Logo img{
        left: -37%;
    }
}

@media  (min-width: 1920px) {

    #Section1{
        padding: 0px 6%;
    }
    .S1-Logo img{
        left: -47%;
    }
}

