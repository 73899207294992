/* .footer .content .section1 .iconPancho{
    background-color: #008392;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 40%;
    border-radius: 50%;
    flex: none;
    padding: 6%;
    min-width: 54px;
    min-height: 54px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.footer .content .section1 .iconPancho img{
    width: 100%;
    height: 100%;
}

.footer .content .section1 .Our-SM{
    display: flex;
    align-items: center; 
    flex-direction: column;
    width: 50%;
}
.footer .content .section1 .Our-SM h4{
    color: #008392;
    font-family: 'Quicksand';
    text-align: center;
    text-transform: uppercase;
    font-size: 3.7vw;
    font-weight: 600;
    /* margin: 0px;     
    white-space: nowrap;
}

.footer .content .section1 .Our-SM .SM-icons{
    display: flex;
    align-items: center;
    justify-content: center;
}

/** Querys */

@media (min-width: 0px) and (max-width: 768px){
    .footer .content .footer-internal-container{
        display: flex !important;
        flex-direction: column;
        align-items: flex-start;
    }

    .footer .content .footer-internal-container .section3{
        width: 100%;
        padding-bottom: 3rem;
    }

    .bottom{
        padding: 0 15px;
    }
    .bottom .footer-internal-container-B p{
        text-align: center;
    }
}

@media  (min-width: 576px) {
    .footer-internal-container,
    .footer-internal-container-B{
        max-width: 540px;
    }
}
@media  (min-width: 768px) {
    .footer-internal-container,
    .footer-internal-container-B{
        max-width: 720px;
    }
}
@media  (min-width: 992px) {
    .footer-internal-container,
    .footer-internal-container-B{
        max-width: 960px;
    }
}
@media  (min-width: 1200px) {
    .footer-internal-container,
    .footer-internal-container-B{
        max-width: 1140px;
    }
}
@media  (min-width: 1400px) {
    .footer-internal-container,
    .footer-internal-container-B{
        max-width: 1320px;
    }
}

