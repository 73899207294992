@-webkit-keyframes slide-right {
  0% {
    transform: translateX(-200%);
    -webkit-transform: translateX(-200%);
    -moz-transform: translateX(-200%);
    -ms-transform: translateX(-200%);
    -o-transform: translateX(-200%);
}
  100% {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
}
}

@keyframes slide-right {
  0% {
    transform: translateX(-200%);
    -webkit-transform: translateX(-200%);
    -moz-transform: translateX(-200%);
    -ms-transform: translateX(-200%);
    -o-transform: translateX(-200%);
}
  100% {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
}
}

.isMovil{
    display: flex;
    position: fixed;
    left: 0px;
    top: 0px;
    background:  #FFF;
    height: 100vh;
    min-height: 100vh;
    width: 250px;
    animation: slide-right 200ms cubic-bezier(0.694, 0.0482, 0.335, 1)forwards;
    -webkit-animation: slide-right 200ms cubic-bezier(0.694, 0.0482, 0.335, 1)forwards;
    min-width: 224px;
    z-index: 200;
}

.isMovil ul{
    display: flex;
    /* justify-content: center; */
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin: 0px 0px 0px 0px; 
    list-style: none;
}

.isMovil .iconClose{
    height: 56px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 13px;
}

.isMovil .iconClose img{
    height: 1.4em;
    position: relative;
    left: 37px;
}

.isMovil .logo-menu{
  position: absolute;
  width: 100%;
  height: 66px;
  bottom: 23px;
}

.isMovil .logo-menu img{
  height: 100%;
}

.isMovil ul li { 
  /* height: auto; */
  width: 100%;     
  padding: 0px 30px;
  display: flex;
  /* background-color: #FFF; */
    
}
.isMovil ul li  a{ 
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  /* background-color: #89d3d4;   */
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
}
.isMovil .isHeader {      
    /* font-size: 6vw; */
    gap: 0.5%;
    
}

.isMovil .drpdwnLi a{
  color: #212529 ;
}

.close-btn{
    position: absolute;
    width: 8%;
    top: 7%;
    right: 8%;
    
}

/* @media  (min-width: 426px) {
  .isMovil .isHeader {
    font-size: 5vw;
  }
}
@media  (min-width: 560px) {
  .isMovil .isHeader {
    font-size: 4.6vw;
  }
}
@media  (min-width: 643px) {
  .isMovil .isHeader {
    font-size: 4.3vw;
  }
}
@media  (min-width: 720px) {
  .isMovil .isHeader {
    font-size: 3.7vw;
  }
}
@media  (min-width: 800px) {
  .isMovil .isHeader {
    font-size: 3.5vw;
  }
} */


  